import { Box, CircularProgress, Typography } from '@mui/material';
import { PropsWithChildren, createContext, useContext, useMemo } from 'react';
import { useEffectOnce } from 'react-use';
import { useListClientProjects } from '@/api';
import { Project } from '@/types';

const ProjectsContext = createContext<Project[] | null>(null);

function ProjectsProvider({ children }: PropsWithChildren) {
  const { data, isLoading, trigger } = useListClientProjects();

  useEffectOnce(() => {
    trigger();
  });

  const memoisedProjects = useMemo(() => {
    return filterUnavailableProjects(data?.projects || []);
  }, [data?.projects]);

  return isLoading ? (
    <Box>
      <CircularProgress />
      <Typography>案件リストを取得しています...</Typography>
    </Box>
  ) : (
    <ProjectsContext.Provider value={memoisedProjects}>{children}</ProjectsContext.Provider>
  );
}

function filterUnavailableProjects(projects: Project[]): Project[] {
  return projects.filter((project) => project.isAvailable);
}

function useProjects(): Project[] {
  const context = useContext(ProjectsContext);

  if (!context) {
    throw new Error('useProjects must be used within a ProjectsProvider');
  }
  return context;
}

export { ProjectsProvider, useProjects };
