import {
  Alert,
  AlertTitle,
  Avatar,
  Box,
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  TextField,
  Typography,
} from '@mui/material';
import { useErrorAlert } from 'lib/ErrorAlert';
import { useState } from 'react';
import { useAuth } from '../hooks/useAuth';
import { LockOutlined } from '@mui/icons-material';

export const LoginScreen = () => {
  const { isLoading, login } = useAuth();
  const [isError, setIsError] = useState(false);
  const { clearErrors } = useErrorAlert();

  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlined />
        </Avatar>
        <Typography component='h1' variant='h5'>
          Ecois Client ログイン
        </Typography>
        {isError && (
          <Alert sx={{ width: '100%' }} severity='error'>
            <AlertTitle>認証エラー</AlertTitle>
            メールアドレスとパスワードの組み合わせが正しくありません。
          </Alert>
        )}
        <Box
          component='form'
          onSubmit={(e) => {
            e.preventDefault();
            const username = (e.target as HTMLFormElement).username.value;
            const password = (e.target as HTMLFormElement).password.value;
            login({ username, password })
              .then(() => {
                setIsError(false);
                clearErrors();
              })
              .catch(() => {
                setIsError(true);
              });
          }}
          noValidate
          sx={{ mt: 1 }}
        >
          <TextField
            margin='normal'
            required
            fullWidth
            id='username'
            label='Username'
            name='username'
            autoComplete='username'
            tabIndex={0}
          />
          <TextField
            margin='normal'
            required
            fullWidth
            name='password'
            label='Password'
            type='password'
            id='password'
            autoComplete='current-password'
          />
          <Button
            disabled={isLoading}
            type='submit'
            fullWidth
            variant='contained'
            sx={{ mt: 3, mb: 2 }}
          >
            {!isLoading ? 'ログイン' : <CircularProgress color='secondary' />}
          </Button>
        </Box>
      </Box>
    </Container>
  );
};
