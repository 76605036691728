import { Box, LinearProgress, Typography } from '@mui/material';
import * as Sentry from '@sentry/browser';
import { useAuthLS } from 'lib/hooks/useAuthLS';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { AuthContext } from '../context/AuthContext';
import { AuthState } from '../types/Auth';
import { useCreateClientToken, useReadClientUserProfile } from '@/api';

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const { setToken, getToken, removeToken } = useAuthLS();
  const { data: userProfile, trigger: getUserProfile } = useReadClientUserProfile();
  const accessToken = getToken('accessToken');
  const { data, trigger, error, isLoading, reset } = useCreateClientToken();

  const [authState, setAuthState] = useState<AuthState>('IDLE');

  const profile = useMemo(() => {
    if (userProfile?.clientUser?.isAvailable) {
      return userProfile.clientUser;
    }
    return undefined;
  }, [userProfile]);

  const login = useCallback(
    async ({ username, password }: { username: string; password: string }) => {
      await trigger({
        body: {
          username,
          password,
        },
        config: {
          headers: {
            'content-Type': 'application/x-www-form-urlencoded',
          },
        },
      });

      if (error) {
        console.error(error);
      }
    },
    [error, trigger],
  );

  function setSentryUser(id: number, username: string, email: string) {
    Sentry.setUser({
      id: id,
      username: username,
      email: email,
    });
  }

  useEffect(() => {
    // 認証状態を確認する
    (async () => {
      if (accessToken) {
        const userProfile = await getUserProfile();
        if (userProfile?.clientUser?.isAvailable) {
          setAuthState('AUTHENTICATED');
          setSentryUser(
            userProfile.clientUser.id,
            userProfile.clientUser.username,
            userProfile.clientUser.email,
          );
        } else {
          setAuthState('UNAUTHENTICATED');
        }
      } else {
        setAuthState('UNAUTHENTICATED');
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  useEffect(() => {
    // ポストリクエストが成功する ＝ dataが更新されている
    if (data) {
      setAuthState('AUTHENTICATED');
      setToken({
        accessToken: data.access_token,
        refreshToken: data.refresh_token,
      });
    }
  }, [data, setToken]);

  const logout = useCallback(
    ({ callback }: { callback?: () => void } = {}) => {
      reset();
      removeToken('both');
      setAuthState('UNAUTHENTICATED');
      callback && callback();
    },
    [removeToken, reset],
  );

  const AuthValue = useMemo(
    () => ({
      authState,
      setAuthState,
      accessToken,
      login,
      logout,
      isLoading,
      profile,
    }),
    [accessToken, authState, isLoading, login, logout, profile],
  );
  if (authState === 'IDLE') {
    return (
      <Box>
        <LinearProgress />
        <Box p={2}>
          <Typography variant='h6'>Ecois Client</Typography>
          <Typography variant='body1'>
            アプリケーションを起動中です。この表示が続く場合はブラウザリロードを行ってください。
          </Typography>
        </Box>
      </Box>
    );
  }

  return <AuthContext.Provider value={AuthValue}>{children}</AuthContext.Provider>;
};
