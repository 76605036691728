import { SentryCommonConfig } from 'lib/sentry';
import ReactDOM from 'react-dom/client';

import App from './App';
import './styles/global.css';

SentryCommonConfig();

const root = ReactDOM.createRoot(document.getElementById('root')!);

if (import.meta.env.MODE === 'development') {
  const { worker } = await import('./mocks/browser');
  await worker.start({ onUnhandledRequest: 'bypass' });
  root.render(<App />);
} else {
  root.render(<App />);
}
