import { Endpoints, abstractFetcher, useEcoisSWRMutation } from 'lib/api';
import {
  CreateClientTokenRequestBody,
  CreateClientTokenResponseBody,
  CreateClientTokenUnprocessableEntity,
} from './types';

function useCreateClientToken() {
  const { data, error, isMutating, trigger, ...props } = useEcoisSWRMutation<
    CreateClientTokenResponseBody,
    {
      body: CreateClientTokenRequestBody;
      config: any;
    },
    CreateClientTokenUnprocessableEntity
  >(Endpoints.CreateClientToken, abstractFetcher);

  return {
    data,
    error,
    isLoading: isMutating,
    trigger,
    ...props,
  };
}

export { useCreateClientToken };
