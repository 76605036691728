import { ErrorAlertProvider } from 'lib/ErrorAlert';
import { RouterProvider } from 'react-router-dom';
import { router } from '../routes/Router';
import { AuthProvider } from './AuthProvider';
import { MuiThemeProvider } from './MuiThemeProvider';
import { SWRConfigProvider } from './SWRConfigProvider';

export const AppProviders = ({ children }: { children?: React.ReactNode }) => {
  return (
    <>
      <MuiThemeProvider>
        <ErrorAlertProvider>
          <SWRConfigProvider>
            <AuthProvider>
              <RouterProvider router={router} />
              {children}
            </AuthProvider>
          </SWRConfigProvider>
        </ErrorAlertProvider>
      </MuiThemeProvider>
    </>
  );
};
