import { Menu, ListSubheader, MenuItem, Divider } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@/hooks';

function useDropdownMenu() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openState = Boolean(anchorEl);

  function open(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  function close() {
    setAnchorEl(null);
  }

  const props = {
    anchorEl,
    openState,
    onClose: close,
  };

  return { props, open, DropdownMenu };
}

function DropdownMenu({
  anchorEl,
  openState,
  onClose,
}: {
  anchorEl: HTMLElement | null;
  openState: boolean;
  onClose: () => void;
}) {
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout({
      callback: () => {
        navigate('/login');
      },
    });
  };

  return (
    <Menu
      id='menu-globalmenu-bar'
      anchorEl={anchorEl}
      open={openState}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      sx={{
        '& .MuiMenu-paper': {
          minWidth: 200,
        },
      }}
    >
      <ListSubheader> ユーザーメニュー </ListSubheader>
      <Divider />
      <MenuItem onClick={handleLogout}>ログアウト</MenuItem>
    </Menu>
  );
}

export { useDropdownMenu };
