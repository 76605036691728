import { ErrorAlertComponent } from 'lib/ErrorAlert';
import { Outlet } from 'react-router-dom';
import { GlobalMenuBar } from '@/components/GlobalMenuBar';
import { ProjectsProvider } from '@/providers/ProjectsProvider';
import { Box } from '@mui/material';

export const AppShell = () => {
  const pages = [
    { label: 'サマリー', to: '/' },
    { label: 'グラフ', to: '/graph' },
  ];

  return (
    <Box sx={{ display: 'flex' }} justifyContent={'center'}>
      <ErrorAlertComponent float />
      <GlobalMenuBar pages={pages} />
      <ProjectsProvider>
        <Outlet />
      </ProjectsProvider>
    </Box>
  );
};
