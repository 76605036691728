import { KeyboardArrowDown } from '@mui/icons-material';
import { AppBar, Toolbar, Typography, Box, Button, Grid, styled } from '@mui/material';
import { Link, NavLink, NavLinkProps } from 'react-router-dom';
import { useDropdownMenu } from './hooks/useDropdownMenu';
import { useAuth } from '@/hooks';

type Pages = {
  label: string;
  to: string;
};

const EcoisNavLink = styled((props: NavLinkProps) => <NavLink {...props} />)(({ theme }) => ({
  fontSize: theme.typography.body2.fontSize,
  padding: theme.spacing(1, 1.5),
  borderRadius: theme.shape.borderRadius * 100,
  color: theme.palette.common.white,
  textDecoration: 'none',
  backgroundColor: 'transparent',
  '&:hover': {
    opacity: 0.8,
  },
  '&.active': {
    backgroundColor: theme.palette.primary.dark,
    opacity: 1,
  },
}));

function GlobalMenuBar({ pages }: { pages: Pages[] }) {
  const { profile } = useAuth();
  const { DropdownMenu, props, open } = useDropdownMenu();

  return (
    <AppBar position='fixed' sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Toolbar>
        <Typography
          component={Link}
          variant='h6'
          to='/'
          mr={4}
          sx={{
            color: 'white',
            textDecoration: 'none',
          }}
        >
          Ecois Client
        </Typography>
        <Box flexGrow={1}>
          <Grid container spacing={2}>
            {pages.map(({ label, to }) => (
              <Grid item key={label}>
                <EcoisNavLink to={to}>{label}</EcoisNavLink>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Button
          color='inherit'
          sx={{
            textTransform: 'none',
          }}
          onClick={open}
          endIcon={<KeyboardArrowDown />}
        >
          {profile?.username}
        </Button>
        <DropdownMenu {...props} />
      </Toolbar>
    </AppBar>
  );
}

export { GlobalMenuBar };
