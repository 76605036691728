import { sentryCreateBrowserRouter } from 'lib/sentry';
import { lazy, Suspense, PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';
import { AppShell } from '../layout/AppShell';
import { LoginPage } from '../pages/LoginPage';
import { AuthGuard } from './AuthGuard';

const GraphPage = lazy(() => import('../pages/GraphPage'));
const NotFoundPage = lazy(() => import('../pages/NotFoundPage'));
const SummaryPage = lazy(() => import('../pages/SummaryPage'));
// const UserPage = lazy(() => import('../pages/UserPage'));

export const router = sentryCreateBrowserRouter([
  {
    path: '/login',
    element: <LoginPage />,
  },
  {
    path: '/',
    element: <AppShell />,
    children: [
      {
        path: '/',
        element: (
          <RouteWrapper>
            <SummaryPage />
          </RouteWrapper>
        ),
      },
      {
        path: '/graph',
        element: (
          <RouteWrapper>
            <GraphPage />
          </RouteWrapper>
        ),
      },
      {
        path: '/notfound',
        element: (
          <RouteWrapper>
            <NotFoundPage />
          </RouteWrapper>
        ),
      },
      {
        path: '*',
        element: <Navigate to={'notfound'} replace />,
      },
    ],
  },
]);

function RouteWrapper({ children }: PropsWithChildren) {
  return (
    <AuthGuard redirectTo='/login'>
      <Suspense fallback={<div>Page is Loading...</div>}>{children}</Suspense>
    </AuthGuard>
  );
}
