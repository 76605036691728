import { useEcoisSWRMutation, abstractFetcher, Endpoints } from 'lib/api';
import { ListClientProjectsResponse } from '@/api/types';

function useListClientProjects() {
  const { data, error, isMutating, trigger, ...props } =
    useEcoisSWRMutation<ListClientProjectsResponse>(Endpoints.ListClientProjects, abstractFetcher);

  return {
    data,
    error,
    isLoading: isMutating,
    trigger,
    ...props,
  };
}

export { useListClientProjects };
