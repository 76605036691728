import { createContext } from 'react';
import { AuthState } from '../types/Auth';
import { ClientUser } from '@/types';

export type AuthContextType = {
  authState: AuthState;
  accessToken: string | null;
  setAuthState: (status: AuthState) => void;
  login: (param: { username: string; password: string }) => Promise<void>;
  logout: ({ callback }: { callback: any }) => void;
  isLoading: boolean;
  profile: ClientUser | undefined;
};

export const AuthContext = createContext<AuthContextType | null>(null);
